import React, { useState, useEffect } from 'react';

function ImageDisplay(props) {
	const [fullScreenImage, setFullScreenImage] = useState(false);
	const [hideInfo, setHideInfo] = useState(true);
	const [hideFullscreenInfo, setHideFullscreenInfo] = useState(false);

	useEffect(() => {
		setFullScreenImage(false);
		setHideFullscreenInfo(false);
		setHideInfo(true);

		return () => {
			setFullScreenImage(false);
		}
	}, [props.data]);

	return (
		<React.Fragment>
			<div className={'profile-image '+(props.className || '')}
				onMouseEnter={() => setHideInfo(false)}
				onMouseLeave={() => setHideInfo(true)}
				onClick={() => setFullScreenImage(true)}
			>
				<img title={props.title}
					role="button"
					src={props.src}
					className="mx-auto d-block rounded"
				/>

				{
					(props.title || props.text) &&
					<div className={'title-container'+(hideInfo ? ' hidden' : '')}>
						 {
							 props.title
						 }
						 {
							 props.text &&
							 <React.Fragment>
								<br/>
								<small dangerouslySetInnerHTML={{__html: props.text}} />
							</React.Fragment>
						 }
					</div>
				}
			</div>

			<div className={'image-overlay-wrapper'+(fullScreenImage ? ' visible' : '')}
				onClick={() => setFullScreenImage(false)}
			>
				{
					fullScreenImage &&
					<div className="image-display" onMouseEnter={() => setHideFullscreenInfo(true)} onMouseLeave={() => setHideFullscreenInfo(false)}>
						<img src={props.src} />
						{
							(props.title || props.text) &&
							<div className={'title-container'+(hideFullscreenInfo ? ' hidden' : '')}>
								 {
									 props.title
								 }
								 {
									 props.text &&
									 <React.Fragment>
									 	<br/>
										<small dangerouslySetInnerHTML={{__html: props.text}} />
									</React.Fragment>
								 }
							</div>
						}
					</div>
				}
			</div>
		</React.Fragment>
	)
}

export default ImageDisplay;
