import React, { useState, useEffect, Fragment } from 'react';
import MediaDisplay from './MediaDisplay';

function PdfDisplay(props) {
	const [fullScreen, setFullScreen] = useState(false);

	return (
		<Fragment>			
			{
				fullScreen && <MediaDisplay media={{
					url: props.src
				}} onClose={() => setFullScreen(false)} />
			}
			<div className="pdf-display">
				<button className="btn mb-2 float-right" onClick={() => setFullScreen(true)}>Stækka</button>
				<embed width="100%" style={{height: 800, maxHeight: '100vh'}} src={props.src} className="pdf" type="application/pdf" />
			</div>
		</Fragment>
	)
}

export default PdfDisplay;
