import React, { Component, Suspense, createRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect, withRouter } from "react-router-dom";
import _ from 'underscore';

import EventBus from 'eventbusjs';

import SearchBox from './components/SearchBox.js';
import SiteFooter from './components/ui/SiteFooter.js';
//import AudioView from './components/AudioView.js';
//import PlaceView from './components/PlaceView.js';
//import PersonView from './components/PersonView.js';
//import FolkloreView from './components/FolkloreView.js';
import GenericList from './components/GenericList.js';
import ContentWrapper from './components/ui/ContentWrapper.js';
import GlobalAudioPlayer from './components/ui/GlobalAudioPlayer';
import SavedItemsView from './components/ui/SavedItemsView';
import LoadingBar from './components/ui/LoadingBar';
import AudioList from './components/AudioList';
import PersonList from './components/PersonList';
import PlaceList from './components/PlaceList';
import GroupList from './components/GroupList';
import ThingList from './components/ThingList';
import StanzaList from './components/StanzaList';
import PoemList from './components/PoemList';
import BookList from './components/BookList';
import LettersList from './components/LettersList';
import Loader from './components/ui/Loader';
import SiteTitle from './components/ui/SiteTitle';
import PageMenu from './components/ui/PageMenu';
import InterviewList from './components/InterviewList';
import MeterView from './components/MeterView';
import PageView from './components/ui/PageView';
import ProjectView from './components/ProjectView';
import GoogleAnalytics from './components/ui/GoogleAnalytics';
//import SongList from './components/SongList.js';

import helpers from './lib/helpers';
import localLibrary from './lib/localLibrary';
import MeterList from './components/MeterList.js';
import ProjectEntryView from './components/ProjectEntryView.js';

const SagnirList = React.lazy(() => import('./components/SagnirList'));
const SagnirMap = React.lazy(() => import('./components/SagnirMap'));
const EventsList = React.lazy(() => import('./components/EventsList'));
const EventsTimeline = React.lazy(() => import('./components/EventsTimeline'));
const FolkloreSearch = React.lazy(() => import('./components/FolkloreSearch'));
const AudioView = React.lazy(() => import('./components/AudioView'));
const AudioEmbedView = React.lazy(() => import('./components/AudioEmbedView'));
const PlaceView = React.lazy(() => import('./components/PlaceView'));
const PersonView = React.lazy(() => import('./components/PersonView'));
const SagnirView = React.lazy(() => import('./components/SagnirView'));
const StanzaView = React.lazy(() => import('./components/StanzaView'));
const InterviewView = React.lazy(() => import('./components/InterviewView'));
const LetterView = React.lazy(() => import('./components/LetterView'));
const EventView = React.lazy(() => import('./components/EventView'));
const ThingView = React.lazy(() => import('./components/ThingView'));
const GroupView = React.lazy(() => import('./components/GroupView'));
const KeywordView = React.lazy(() => import('./components/KeywordView'));
const PoemView = React.lazy(() => import('./components/PoemView'));
const SearchResults = React.lazy(() => import('./components/SearchResults'));
const BookView = React.lazy(() => import('./components/BookView'));
const SongView = React.lazy(() => import('./components/SongView'));
const SagnagerdView = React.lazy(() => import('./components/SagnagerdView.js'));
const MelodyList = React.lazy(() => import('./components/MelodyList'));
const SongList = React.lazy(() => import('./components/SongList'));
const StanzaOfTheDayView = React.lazy(() => import('./components/ui/StanzaOfTheDayView'));

let l = window.l;

class App extends Component {
	constructor(props) {
		super(props);

		this.router = createRef();

		window.eventBus = EventBus;

		window.eventBus.addEventListener('Lang.languageChanged', function() {
			this.forceUpdate();
		}.bind(this));

		window.eventBus.addEventListener('Lang.languageDataUpdate', function() {
			this.forceUpdate();
		}.bind(this));

		this.menuButtonClickHandler = this.menuButtonClickHandler.bind(this);

		localLibrary.registerSignal(function() {
			this.forceUpdate();
		}.bind(this));

		this.state = {
			sitePart: [],
			currentPage: '',
			currentUrl: '/',
			menuOpen: false
		}
	}

	componentDidMount() {
		l = window.l;
		window.eventBus.addEventListener('audio.playervisible', this.audioPlayerVisibleHandler.bind(this));

		this.router.current.history.listen((event) => {
			// Ekki alltaf skrolla upp þegar slóð breytist, ekki þegar hlustað er á nýtt hljóðrit eða þegar flakkað er á milli tabba - TODO
			if (
				!this.state.currentUrl.startsWith(helpers.routerUrls['audio']) &&
				!event.pathname.startsWith(helpers.routerUrls['audio'])
			) {
				if (helpers.routeBasePath(this.state.currentUrl) != helpers.routeBasePath(event.pathname)) {
					window.scrollTo(0, 0);
				}
			}

			this.checkSitePart(event.pathname)

			this.setState({
				currentUrl: event.pathname,
				menuOpen: false
			});
		});

		document.addEventListener('mousedown', function(event) {
			if (!this.state.menuOpen) {
				return;
			}

			if (this.refs.menuButton.contains(event.target)) {
				event.stopPropagation();

				return;
			}

			if (this.refs.menuContent && !this.refs.menuContent.contains(event.target)) {
				this.setState({
					menuOpen: false
				})
			}
		}.bind(this));

		window.eventBus.addEventListener('AppMenu.close', function() {
			this.setState({
				menuOpen: false
			});
		}.bind(this));

		this.setState({
			currentUrl: this.router.current.history.location.pathname
		});

		this.checkSitePart(this.router.current.history.location.pathname);

		try {
			if (this.router.current.history.location.hash != '') {
				if (this.router.current.history.location.hash.startsWith('#lang=')) {
					setTimeout(() => window.Lang.setCurrentLang(this.router.current.history.location.hash.split('=')[1]), 500);
				}
			}
		}
		catch (e) {}
	}

	menuButtonClickHandler(event) {
		event.stopPropagation();
		if (!this.state.menuOpen) {
			this.refs.menuContent.scrollTop = 0;
		}

		this.setState({
			menuOpen: !this.state.menuOpen
		});
	}

	checkSitePart(sitePart) {
		let urlFrags = sitePart.toLowerCase().split('/');
		let urlFragsCompact = _.compact(sitePart.toLowerCase().split('/').filter((part) => !helpers.isNumber(part)));

		let currentPage = urlFragsCompact.length > 0 ? urlFragsCompact[urlFragsCompact.length-1] : '';

		if (urlFrags.length > 0) {
			if (helpers.isNumber(urlFrags[urlFrags.length-1])) {
				currentPage += '-item';
			}
		}

		let siteParts = [];

		let headerTitle;
		let headerSubTitle;

		if (sitePart.toLowerCase().startsWith('/tjodfraedi')) {
			siteParts.push('folklore');

			//headerTitle = 'Þjóðfræði';
		}

		if (sitePart.toLowerCase().startsWith('/tonlist')) {
			siteParts.push('music');

			//headerTitle = 'Tónlistarsafn';
		}

		if (sitePart.toLowerCase().startsWith('/embed')) {
			siteParts.push('embed');

			//headerTitle = 'Tónlistarsafn';
		}

		if (sitePart.toLowerCase().startsWith('/tjodfraedi/sagnir')) {
			siteParts.push('sagnagrunnur');

			headerTitle = 'Sagnagrunnur';
		}

		if (sitePart.toLowerCase().startsWith('/tjodfraedi/aevintyri')) {
			siteParts.push('aevintyri');

			headerTitle = 'Ævintýragrunnur';
			//headerSubTitle = 'Ævintýragrunnur';
		}

		if (sitePart.toLowerCase().startsWith('/bragi')) {
			siteParts.push('bragi');

			headerTitle = 'Bragi, óðfræðivefur';
		}

		this.setState({
			sitePart: siteParts,
			currentPage: currentPage,
			frontPage: sitePart == '/',
			headerTitle: headerTitle,
			headerSubTitle: headerSubTitle
		});
	}

	audioPlayerVisibleHandler() {
		document.body.classList.add('has-docked-control');
	}

	render() {
		return (
			<div className={'app '+(this.state.sitePart.map((sitePart) => 'sitepart-'+sitePart).join(' '))+' page-'+this.state.currentPage}>
				<Router ref={this.router}>

					<Switch>
					<Route path={helpers.routerUrls['hljodrit_embed']+':id'} render={(routeParams) => {
							return <Suspense fallback={<Loader />}>
								<AudioEmbedView searchParams={routeParams.location.search} {...routeParams} />
							</Suspense>
						}} />

						<Route path={helpers.routerUrls['sagnir_map_embed']} render={(routeParams) => {
							return <Suspense fallback={<Loader />}>
								<SagnirMap searchParams={routeParams.location.search} {...routeParams} />
							</Suspense>
						}} />

						<Route path="/">

							<div className="header">
								<div className="container position-relative">
									<Link to="/"><img className="site-logo" src="/img/logo.png" /></Link>

									{
										this.state.headerTitle &&
										<h3 className={'sitepart-title'+(this.state.headerSubTitle ? ' with-subtitle' : '')}>
											{this.state.headerTitle}
											{
												this.state.headerSubTitle &&
												<small>{this.state.headerSubTitle}</small>
											}
										</h3>
									}

									<button className="btn-menu hidden-mobile-up" ref="menuButton" onClick={this.menuButtonClickHandler}>
										<div className="hamburger-icon">
											<div className="icon-middle" />
											<div className="icon-before-after" />
										</div>
									</button>

									<PageMenu className="hidden-mobile" />
								</div>
							</div>

							<div className="search-container">
								<div className="container">
									<SearchBox />
								</div>
							</div>

							<Switch>

								<Route exact path="/">
									<PageView url="/" />
								</Route>

								<React.Fragment>

										<Switch>

											<Route path={helpers.routerUrls['vidburdir_timeline']+':id?'} render={(routeParams) => {
												return <React.Fragment>
													<SiteTitle title="Viðburðir" />

													<Suspense fallback={<Loader />}>
														<EventsTimeline searchParams={routeParams.location.search} enableFilter={true} />
													</Suspense>
												</React.Fragment>;
											}} />

											<div className="container" computedMatch={null}>
												<Switch>
													<Route exact path={helpers.routerUrls['saveditems']} render={(routeProps) => (
														<React.Fragment>
															<SiteTitle title="Handraðinn" />
															<SavedItemsView />
														</React.Fragment>
													)} />

													<Route path={helpers.routerUrls['tjodfraedi']}>
														<Switch>

															<Route path={helpers.routerUrls['tjodfraedi_leit']+':tab?'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<ContentWrapper>
																		<SiteTitle title={window.l('Þjóðfræðileit')} />
																		<PageView url={helpers.routerUrls['tjodfraedi_leit']} fallback={<h2>{window.l('Þjóðfræðileit')}</h2>} />
																		<FolkloreSearch {...routeParams} searchParams={routeParams.location.search} />
																	</ContentWrapper>
																</Suspense>
															}} />

															{/* ROUTER FYRIR REDIRECT Á UUID */}
															<Route path={helpers.routerUrls['hljodrit']+':id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<AudioView searchParams={routeParams.location.search} {...routeParams} />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['hljodrit']+':id(\\d+)/:tab?'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<AudioView searchParams={routeParams.location.search} {...routeParams} />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['hljodrit']+':tab?'} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Hljóðrit" />
																	<PageView url={helpers.routerUrls['hljodrit']} />
																	<AudioList disablePlayButtonRouteChange={true}
																		viewTabs={true}
																		searchParams={routeParams.location.search}
																		enableFilter={true}
																		{...routeParams} />
																</ContentWrapper>
																return
															}} />

															<Route path={[helpers.routerUrls['sagnir_aevintyri']+':id(\\d+)/:tab?', helpers.routerUrls['sagnir']+':id(\\d+)/:tab?', helpers.routerUrls['aevintyri']+':id(\\d+)/:tab?']} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<SagnirView {...routeParams} />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['sagnir_aevintyri']+':tab?'} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Sagnir og ævintýri" />
																	<PageView url={helpers.routerUrls['sagnir_aevintyri']} />
																	<Suspense fallback={<Loader />}>
																		<SagnirList viewTabs={true} searchParams={routeParams.location.search} enableFilter={true} {...routeParams} />
																	</Suspense>
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['aevintyri']+':tab?'} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Ævintýragrunnur" />
																	<PageView url={helpers.routerUrls['aevintyri']} />
																	<Suspense fallback={<Loader />}>
																		<SagnirList path={helpers.routerUrls['aevintyri']} viewTabs={true} form={1000013} searchParams={routeParams.location.search} enableFilter={true} {...routeParams} />
																	</Suspense>
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['sagnir']+':tab?'} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Sagnagrunnur" />
																	<PageView url={helpers.routerUrls['sagnir']} />
																	<Suspense fallback={<Loader />}>
																		<SagnirList path={helpers.routerUrls['sagnir']} viewTabs={true} form={1000003} searchParams={routeParams.location.search} enableFilter={true} {...routeParams} />
																	</Suspense>
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['sagnagerd']+':id(\\d+)/:tab?'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<SagnagerdView className="mt-5" />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['sagnagerd']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Sagnagerðir og minni" />
																	<PageView url={helpers.routerUrls['sagnagerd']} fallback={<h2>Sagnagerðir og minni</h2>} />
																	<GenericList apiEndPoint={'keys'}
																		searchParams={routeParams.location.search}
																		viewUrl={helpers.routerUrls['sagnagerd']}
																		contentField={'text'}
																		filters={[
																			{
																				label: 'Leit',
																				paramName: 'leit',
																				type: 'text',
																				apiParam: 'name'
																			}
																	   	]} />
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['efnisord']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Efnisorð" />
																	<PageView url={helpers.routerUrls['efnisord']} fallback={<h2>Efnisorð</h2>} />
																	<GenericList apiEndPoint={'keywords'}
																		searchParams={routeParams.location.search}
																		viewUrl={helpers.routerUrls['tjodfraedi_leit']+'?efnisord='}
																		filters={[
																			{
																				label: 'Leit',
																				paramName: 'leit',
																				type: 'text',
																				apiParam: 'name_startswith'
																			}
																	   	]} />
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['form']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Form" />
																	<PageView url={helpers.routerUrls['form']} fallback={<h2>Form</h2>} />
																	<GenericList apiEndPoint={'form'}
																		searchParams={routeParams.location.search}
																		viewUrl={helpers.routerUrls['tjodfraedi_leit']+'?form='}
																		filters={[
																			{
																				label: 'Leit',
																				paramName: 'leit',
																				type: 'text',
																				apiParam: 'name_startswith'
																			}
																	   	]} />
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['safnmark']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Safnmark" />
																	<PageView url={helpers.routerUrls['safnmark']} fallback={<h2>Safnmark</h2>} />
																	<GenericList apiEndPoint={'copy'}
																		searchParams={routeParams.location.search}
																		viewUrl={helpers.routerUrls['tjodfraedi_leit']+'?safnmark='}
																		filters={[
																			{
																				label: 'Leit',
																				paramName: 'leit',
																				type: 'text',
																				apiParam: 'name_startswith'
																			}
																	   	]} />
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['frumgagn']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Frumgagn" />
																	<PageView url={helpers.routerUrls['frumgagn']} fallback={<h2>Frumgagn</h2>} />
																	<GenericList apiEndPoint={'tape'}
																		searchParams={routeParams.location.search}
																		viewUrl={helpers.routerUrls['tjodfraedi_leit']+'?frumgagn='}
																		filters={[
																			{
																				label: 'Leit',
																				paramName: 'leit',
																				type: 'text',
																				apiParam: 'name_startswith'
																			}
																	   	]} />
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['bref']+':id'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<LetterView className="mt-5" />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['bref']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Bréf" />
																	<PageView url={helpers.routerUrls['bref']} fallback={<h2>Bréf</h2>} />
																	<LettersList searchParams={routeParams.location.search} enableFilter={true} />
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['efnisord']+':id/:tab?'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<KeywordView className="mt-5" />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['tjodfraedi_einstaklingar']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Einstaklingar" />
																	<PageView url={helpers.routerUrls['tjodfraedi_einstaklingar']} fallback={<h2>Einstaklingar</h2>} />
																	<PersonList folklore={true} searchParams={routeParams.location.search} enableFilter={true} />
																</ContentWrapper>
															}} />

															<Route exact path={['/tjodfraedi/*']} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<PageView />
																</Suspense>
															}} />

														</Switch>

													</Route>

													<Route path={helpers.routerUrls['tonlist']}>
														<Switch>


															<Route path={[helpers.routerUrls['vidburdir_listi'], helpers.routerUrls['vidburdir']]} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Viðburðir" />
																	<PageView url={helpers.routerUrls['vidburdir']} fallback={<h2>Viðburðir</h2>} />

																	<Suspense fallback={<Loader />}>
																		<EventsList enableFilter={true} searchParams={routeParams.location.search} enableFilter={true} />
																	</Suspense>
																</ContentWrapper>;
															}} />

															<Route path={helpers.routerUrls['vidtol']+':id'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<InterviewView {...routeParams} />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['vidtol']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Viðtöl" />
																	<PageView url={helpers.routerUrls['vidtol']} fallback={<h2>Viðtöl</h2>} />
																	<InterviewList searchParams={routeParams.location.search} enableFilter={true} />
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['vidburdir_timeline']+':id/:tab?'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<EventView {...routeParams} />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['tonlist_einstaklingar']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Einstaklingar" />
																	<PageView url={helpers.routerUrls['tonlist_einstaklingar']} fallback={<h2>Einstaklingar</h2>} />
																	<PersonList music={true} searchParams={routeParams.location.search} enableFilter={true} />
																</ContentWrapper>
															}} />

															{/* ROUTER FYRIR REDIRECT Á UUID */}
															<Route path={helpers.routerUrls['orgel']+':id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<ThingView {...routeParams} />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['orgel']+':id(\\d+)/:tab?'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<ThingView {...routeParams} />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['orgel']+':tab?'} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Orgel" />
																	<PageView url={helpers.routerUrls['orgel']} fallback={<h2>Orgel</h2>} />
																	<ThingList viewTabs={true} searchParams={routeParams.location.search} enableFilter={true} {...routeParams} />
																</ContentWrapper>
															}} />

															{/* ROUTER FYRIR REDIRECT Á UUID */}
															<Route path={helpers.routerUrls['hopar']+':id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<GroupView searchParams={routeParams.location.search} {...routeParams} />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['hopar']+':id/:tab?'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<GroupView searchParams={routeParams.location.search} {...routeParams} />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['hopar']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Hópar" />
																	<PageView url={helpers.routerUrls['hopar']} fallback={<h2>Hópar</h2>} />
																	<GroupList searchParams={routeParams.location.search} enableFilter={true} />
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['baekur']+':id/:tab?'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<BookView searchParams={routeParams.location.search} {...routeParams} />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['baekur']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Bækur og handrit" />
																	<PageView url={helpers.routerUrls['baekur']} fallback={<h2>Bækur og handrit</h2>} />
																	<BookList searchParams={routeParams.location.search} enableFilter={true} />
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['log']+':id/:tab?'} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<SongView searchParams={routeParams.location.search} {...routeParams} />
																</Suspense>
															}} />

															<Route path={helpers.routerUrls['sagnir_aevintyri']+':tab?'} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Sagnir og ævintýri" />
																	<PageView url={helpers.routerUrls['sagnir_aevintyri']} />
																	<Suspense fallback={<Loader />}>
																		<SagnirList viewTabs={true} searchParams={routeParams.location.search} enableFilter={true} {...routeParams} />
																	</Suspense>
																</ContentWrapper>
															}} />



															<Route path={helpers.routerUrls['log']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Lög" />
																	<PageView url={helpers.routerUrls['log']} fallback={<h2>Lög</h2>} />
																	<Suspense fallback={<Loader />}>
																		<SongList searchParams={routeParams.location.search} enableFilter={true} />
																	</Suspense>
																</ContentWrapper>
															}} />

															<Route path={helpers.routerUrls['laglinur']} render={(routeParams) => {
																return <ContentWrapper>
																	<SiteTitle title="Lög" />
																	<PageView url={helpers.routerUrls['log']} fallback={<h2>Lög</h2>} />
																	<Suspense fallback={<Loader />}>
																		<MelodyList searchParams={routeParams.location.search} enableFilter={true} />
																	</Suspense>
																</ContentWrapper>
															}} />

															<Route exact path={['/tonlist/*']} render={(routeParams) => {
																return <Suspense fallback={<Loader />}>
																	<PageView />
																</Suspense>
															}} />

														</Switch>
													</Route>

													<Route path={helpers.routerUrls['bragarhaettir_old']+':id/:tab?'} render={(routeParams) => {
														{
															(() => {
																if (this.router.current) {
																	this.router.current.history.replace(helpers.routerUrls['bragarhaettir']+routeParams.match.params.id)
																}
															})()
														}
													}} />

													<Route path={helpers.routerUrls['bragarhaettir']+':id/:tab?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<MeterView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['bragarhaettir_old']} render={(routeParams) => {
														{
															(() => {
																if (this.router.current) {
																	this.router.current.history.replace(helpers.routerUrls['bragarhaettir']);
																}
															})()
														}
													}} />

													<Route path={helpers.routerUrls['bragarhaettir']} render={(routeParams) => {
														return <ContentWrapper>
															<SiteTitle title="Bragarhættir" />
															<PageView url={helpers.routerUrls['bragarhaettir']} fallback={<h2>Bragarhættir</h2>} />
															<MeterList searchParams={routeParams.location.search} enableFilter={true} />
														</ContentWrapper>
													}} />

													<Route path={helpers.routerUrls['kvaedi_old']+':id/:tab?'} render={(routeParams) => {
														{
															(() => {
																if (this.router.current) {
																	this.router.current.history.replace(helpers.routerUrls['kvaedi']+routeParams.match.params.id)
																}
															})()
														}
													}} />

													<Route path={helpers.routerUrls['kvaedi']+':id'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<PoemView {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['kvaedi_old']} render={(routeParams) => {
														{
															(() => {
																if (this.router.current) {
																	this.router.current.history.replace(helpers.routerUrls['kvaedi']);
																}
															})()
														}
													}} />

													<Route path={helpers.routerUrls['kvaedi']} render={(routeParams) => {
														return <ContentWrapper>
															<SiteTitle title="Ljóð" />
															<PageView url={helpers.routerUrls['kvaedi']} fallback={<h2>Ljóð</h2>} />
															<PoemList searchParams={routeParams.location.search} enableFilter={true} />
														</ContentWrapper>
													}} />

													<Route path={helpers.routerUrls['erindi_old']+':id/:tab?'} render={(routeParams) => {
														{
															(() => {
																if (this.router.current) {
																	this.router.current.history.replace(helpers.routerUrls['erindi']+routeParams.match.params.id)
																}
															})()
														}
													}} />

													<Route path={helpers.routerUrls['handahof']} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<StanzaOfTheDayView {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['erindi']+':id/:tab?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<StanzaView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['erindi_old']} render={(routeParams) => {
														{
															(() => {
																if (this.router.current) {
																	this.router.current.history.replace(helpers.routerUrls['erindi']);
																}
															})()
														}
													}} />

													<Route path={helpers.routerUrls['erindi']} render={(routeParams) => {
														return <ContentWrapper>
															<SiteTitle title="Lausavísur og ljóð" />
															<PageView url={helpers.routerUrls['erindi']} fallback={<h2>Lausavísur og ljóð</h2>} />
															<StanzaList searchParams={routeParams.location.search} enableFilter={true} />
														</ContentWrapper>
													}} />

													{/* ROUTER FYRIR REDIRECT Á UUID */}
													<Route path={helpers.routerUrls['stadir']+':id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<PlaceView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['stadir']+':id([0-9]+)/:tab?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<PlaceView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['stadir']+':tab?'} render={(routeParams) => {
														return <ContentWrapper>
															<SiteTitle title="Staðsetningar" />
															<PageView url={helpers.routerUrls['stadir']} fallback={<h2>Staðir</h2>} />
														 	<PlaceList searchParams={routeParams.location.search} useRouter={true} enableFilter={true} {...routeParams} />
														</ContentWrapper>
													}} />

													{/* ROUTER FYRIR REDIRECT Á UUID */}
													<Route path={helpers.routerUrls['einstaklingar']+':id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<PersonView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['einstaklingar']+':id/:tab?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<PersonView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['einstaklingar']} render={(routeParams) => {
														return <ContentWrapper>
															<SiteTitle title="Einstaklingar" />
															<PageView url={helpers.routerUrls['einstaklingar']} fallback={<h2>Einstaklingar</h2>} />
															<PersonList searchParams={routeParams.location.search} enableFilter={true} />
														</ContentWrapper>
													}} />

													<Route path={helpers.routerUrls['projectentry']+':id/:tab?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<ProjectEntryView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['project']+':id/:tab?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<ProjectView searchParams={routeParams.location.search} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['leit']+':searchTerm/:entityType?'} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<SearchResults searchParams={routeParams.location.search} searchTerm={routeParams.match.params.searchTerm} entityType={routeParams.match.params.entityType} {...routeParams} />
														</Suspense>
													}} />

													<Route path={helpers.routerUrls['leit']}>
														<Redirect to={'/'} />
													</Route>

													<Route exact path={['/*']} render={(routeParams) => {
														return <Suspense fallback={<Loader />}>
															<PageView />
														</Suspense>
													}} />

												</Switch>
											</div>

										</Switch>

								</React.Fragment>

							</Switch>


							<div className={'app-menu'+(this.state.menuOpen ? ' open' : '')} ref="menuContent">
								<div className="container">

									<button className="btn btn-link float-right" onClick={
										function() {
											this.setState({
												menuOpen: false
											});
										}.bind(this)}>
										<div className="hamburger-icon black open">
											<div className="icon-middle" />
											<div className="icon-before-after" />
										</div>
									</button>

									<h5 className="mb-4 pt-2">Ísmús</h5>

									<PageMenu className="hidden-mobile-up" />

								</div>
							</div>

							<GoogleAnalytics />

							<SiteFooter frontPage={this.state.frontPage} />

						</Route>
					</Switch>

					<GlobalAudioPlayer />

				</Router>

			</div>
		)
	}
}

export default App;
