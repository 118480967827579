import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TabContainer from './ui/TabContainer';
import TextDisplay from './ui/TextDisplay';
import Breadcrumb from './ui/Breadcrumb';
import BookmarkButton from './ui/BookmarkButton';
import LinkedItems from './ui/LinkedItems';
import _ from 'underscore';

import config from './../config';
import helpers from './../lib/helpers';
import StanzaList from './StanzaList';
import ImageDisplay from './ui/ImageDisplay';
import PlayButton from './ui/PlayButton';
import LocationParentsView from './ui/LocationParentsView';
import MapView from './MapView';
import MediaDisplay from './ui/MediaDisplay';
import PdfDisplay from './ui/PdfDisplay';

function ProjectEntryView(props) {
	const [data, setData] = useState(null);
	const history = useHistory();

	useEffect(() => {
		if (props.match.params.id) {

			helpers.fetch(config.apiRoot+'/api/projectentries/'+props.match.params.id, (json) => {
				setData(json);
			}, null, 'name');
		}
	}, [props.match.params.id]);

	let itemImage;

	if (data && data.links.length > 0) {
		itemImage = _.find(data.links, (item) => item.image).image.file_path;
	}

	const linkKeys = ['audio', 'folklore', 'location', 'person', 'stanza', 'song'];

	let links = (() => {
		let ret = {};
		linkKeys.forEach((key) => {
			if (data && data.links) {
				data.links.forEach(link => {
					if (link[key]) {
						ret[key] = ret[key] || [];
						ret[key].push(link[key]);
					}
				});
			}
		});

		return ret;
	})();

	return (

			data &&
			<div className="content-container">

				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to={'/'}>Forsíða</Link></li>
						<li className="breadcrumb-item"><Link to={'/verkefni/'}>Verkefni</Link></li>
						<li className="breadcrumb-item"><Link to={'/verkefni/'+data.project.id}>{data.project.title}</Link></li>
						<li className="breadcrumb-item"><Link to={'/verkefni/gagn/'+data.id}>{data.title}</Link></li>
					</ol>
				</nav>


				<div>
					<BookmarkButton id={data.id} title={data.title} type="projectentry" className="float-right" />
					<h2>{data.title}</h2>
				</div>

				<div className="row mt-5">
					<div className={'col-12 col-md-7'}>

						{
							data.info && <TextDisplay className="mt-4" text={data.info} />
						}
	
						<table className="table metadata-table">
							<tbody>
								<tr>
									<td><label>Verkefni</label></td>
									<td><Link to={helpers.routerUrls['project']+data.project.id}><strong>{data.project.title}</strong></Link></td>
								</tr>
								{
									data.keywords && data.keywords.length > 0 && <tr>
										<td><label>{window.l('Efnisorð')}</label></td><td>
											{
												helpers.metadataList(data.keywords, helpers.routerUrls['project']+data.project.id, 'id', 'title', 'efnisord', true)
											}
										</td>
									</tr>

								}
								{
									data.metadata.map((item, index) => <tr key={index}>
										<td width="30%"><label>{item.metadata_type.title}</label></td>
										<td>{item.metadata}</td>
									</tr>)
								}
							</tbody>
						</table>
					</div>

					<div className={'col-12 col-md-5'}>
						{
							itemImage && helpers.isImageFile(itemImage) && <ImageDisplay src={config.mediaUrl+itemImage} />
						}
						{
							itemImage && helpers.isPdfFile(itemImage) && <PdfDisplay src={config.mediaUrl+itemImage} />
						}
						{
							itemImage
						}
					</div>

				</div>

				<TabContainer useRouter={false} selectedTab={props.match.params.tab} baseUrl={helpers.routerUrls['erindi']+props.match.params.id}>
					{
						Object.keys(links).length > 0 && <div tabname="tengt" tabtitle="Tengd gögn">
							<p className="mb-3">Tengd gögn í öðrum gagnasöfnum.</p>

							{
								links.audio && links.audio.length > 0 && <div className="mt-5 mb-3">
									<h4>Hljóðrit</h4>
									<table className="table metadata-table">
										{
											links.audio.map((item, index) => {
												return <tr key={index}>
													<td>
														{
															item.files && item.files.length > 0 &&
															<PlayButton className="small"
																audioUrl={item.files[0]}
																itemId={item.id}
																itemTitle={item.copy ? item.copy.name : ''}
																itemInfo={item.contents}
																onClick={() => {
																	if (helpers.isVideoFile(item.files[0])) {
																		history.push(helpers.routerUrls['hljodrit']+item.id);
																	}
																}} />
														}

													</td>
													<td>
													{
														item.duration && <small>{helpers.formatDuration(item.duration*1000)}</small>
													}
													</td>
													<td>
														<Link to={helpers.routerUrls['hljodrit']+item.id}>
															{
																props.hightlightId && props.hightlightId == item.id &&
																<strong>{helpers.trim(item.contents, 400)}</strong>
															}
															{
																(!props.hightlightId || props.hightlightId != item.id) &&
																<React.Fragment>{helpers.trim(item.contents, 400)}</React.Fragment>
															}
														</Link>
													</td>
													<td>{helpers.metadataList(item.source, helpers.routerUrls['einstaklingar'], 'id', 'name')}</td>
													<td>
														{item.copy ? item.copy.name : ''}
														<small className="float-right">{item.serial_number}</small>
													</td>
												</tr>
											})
										}
									</table>
								</div>
							}

							{
								links.folklore && links.folklore.length > 0 && <div className="mt-5 mb-3">
									<h4>Sagnir/ævintýri</h4>
									<table className="table metadata-table">
										{
											links.folklore.map((item, index) => {
												return <tr key={index}>
													<td><Link to={helpers.routerUrls['sagnir_aevintyri']+item.id}>{item.name}</Link></td>
													<td>{item.source}</td>
													<td>{item.type.length > 0 ? item.type[0].name : ''}</td>
												</tr>
											})
										}
									</table>
								</div>
							}

							{
								links.location && links.location.length > 0 && <div className="mt-5 mb-3">
									<h4>Staðir</h4>

									<MapView className="mt-4" mapData={links.location.map(item => {
										if (item.lat && item.lng) {
											return {
												id: item.id,
												label: item.name,
												latLng: [item.lat, item.lng],
												district: helpers.createLocationParentString(item.parent)
											};
										}
									})}/>

									<table className="table metadata-table">
										{
											links.location.map((item, index) => {
												return <tr key={index}>
													<td><Link to={helpers.routerUrls['location']+item.id}>{item.name}</Link></td>
													<td><LocationParentsView skipFirst={true} location={item} /></td>
												</tr>
											})
										}
									</table>
								</div>
							}
						</div>
					}
				</TabContainer>

			</div>

	)
}

export default ProjectEntryView;
