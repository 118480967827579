import React, { useState, useEffect } from 'react';

function MediaDisplay(props) {
	const [fullScreenImage, setFullScreenImage] = useState(false);
	const [hideInfo, setHideInfo] = useState(true);
	const [hideFullscreenInfo, setHideFullscreenInfo] = useState(false);

	useEffect(() => {
		setFullScreenImage(false);
		setHideFullscreenInfo(false);
		setHideInfo(true);

		setTimeout(() => setFullScreenImage(true), 100);

		return () => {
			closeFullScreen();
		}
	}, [props.media]);

	const closeFullScreen = () => {
		setFullScreenImage(false);

		if (props.onClose) {
			props.onClose();
		}
	}

	let mediaElement = props.media && props.media.url ? (
		props.media.url.toLowerCase().endsWith('.pdf') || props.media.extension == 'pdf' ?
		<embed src={props.media.url} className="pdf" type="application/pdf" /> :
		props.media.url.toLowerCase().endsWith('.mov') || props.media.url.toLowerCase().endsWith('.mp4') ?
		<video controls="controls" className="video" name={props.media.title}>
			<source src={props.media.url} />
			<object CLASSID="clsid:02BF25D5-8C17-4B23-BC80-D3488ABDDC6B" width="320" height="256" CODEBASE="http://www.apple.com/qtactivex/qtplugin.cab">
				<param name="src" value={props.media.url} />
				<param name="autoplay" value="true" />
				<param name="loop" value="false" />
				<param name="controller" value="true" />
				<embed src={props.media.url} width="320" height="256" autoplay="true" loop="false" controller="true" pluginspage="http://www.apple.com/quicktime/"></embed>
			</object>
		</video> :
		<embed />
	) : null;

	return (
		<React.Fragment>
			<div className={'image-overlay-wrapper'+(fullScreenImage ? ' visible' : '')} onClick={() => closeFullScreen()}>
				<div className="image-display" onMouseEnter={() => setHideFullscreenInfo(true)} onMouseLeave={() => setHideFullscreenInfo(false)}>
					{
						mediaElement
					}
					{
						(props.media.title || props.media.text) && true == false &&
						<div className={'title-container'+(hideFullscreenInfo ? ' hidden' : '')}>
							 {
								 props.media.title
							 }
							 {
								 props.media.text &&
								 <React.Fragment>
								 	<br/>
									<small dangerouslySetInnerHTML={{__html: props.media.text}} />
								</React.Fragment>
							 }
						</div>
					}
				</div>
			</div>
		</React.Fragment>
	)
}

export default MediaDisplay;
