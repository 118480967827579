import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ImageDisplay from './ui/ImageDisplay';
import PlayButton from './ui/PlayButton';
import TabContainer from './ui/TabContainer';
import TextDisplay from './ui/TextDisplay';
import BookmarkButton from './ui/BookmarkButton';
import Breadcrumb from './ui/Breadcrumb';
import _ from 'underscore';

import config from './../config';
import helpers from './../lib/helpers';
import ListFilter from './filters/ListFilter';
import PaginationButtons from './PaginationButtons';
import NoResults from './ui/NoResults';

function BookView(props) {
	const [data, setData] = useState(null);
	const [projectEntries, setProjectEntries] = useState(null);
	
	const [currentSearchTerm, setCurrentSearchTerm] = useState(null);
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [loading, setLoading] = useState(true);

	const history = useHistory();

	const pageSize = 50;

	useEffect(() => {
		if (props.match.params.id) {
			helpers.fetch(config.apiRoot+'/api/projects/'+props.match.params.id, (json) => {
				setData(json);
			}, null, 'number');

			fetchList()
		}
	}, [props.match.params.id]);

	useEffect(() => {
		if (!props.data) {
			fetchList();
		}
	}, [props.searchParams]);

	const fetchList = () => {
		setLoading(true);
		setProjectEntries(null);
		setCurrentSearchTerm(null)

		let urlParams = ['limit='+pageSize, 'project='+props.match.params.id];

		if (props.searchParams) {
			let params = helpers.parseSearchParams(props.searchParams)

			if (params.leit) {
				urlParams.push('search='+params.leit);
				setCurrentSearchTerm(params.leit)
			}

			if (params.efnisord) {
				urlParams.push('keyword='+params.efnisord)
			}

			if (params.sida) {
				urlParams.push('limit='+pageSize);
				urlParams.push('offset='+(pageSize*(params.sida-1)))
			}
		}

		helpers.fetch(config.apiRoot+'/api/projectentries/?'+urlParams.join('&'), (json) => {
			setProjectEntries(json.results);
			setLoading(false);
			setNextUrl(json.next);
			setPreviousUrl(json.previous);
			setResultCount(json.count);

		}, null, 'number');

	}
	
	return (

			data &&
			<div className="content-container">

				<Breadcrumb currentElTitle={data.title} />

				<div>
					<BookmarkButton id={data.id} title={data.title} type="project" className="float-right" />
					<h2>{data.title}</h2>
				</div>

				<div className="row mt-5">

					<div className="col-12 col-lg-8">

						{
							data.info && data.info.length > 0 &&
							<TextDisplay className="mt-4" maxLength={400} maxHeight={200} text={data.info} />
						}

					</div>

				</div>
				
				<ListFilter filters={[
					{
						label: 'Leitarorð',
						paramName: 'leit',
						type: 'text'
					}
				]} 
				currentParams={props.searchParams}
				onFilter={(event) => {
					history.push(history.location.pathname+helpers.updateSearchParams(props.searchParams, event));
				}} />

				<div className="image-grid">
					{
						projectEntries && projectEntries.map(item => <Link className="card gray-card grid-item" 
							to={helpers.routerUrls['projectentry']+item.id}
						>
							<div className="card-body item-inner">
								{
									item.images.length > 0 && <div style={{textAlign: 'center'}}>
										<img className={helpers.isPdfFile(item.images[0]) ? 'pdf' : ''} src={helpers.isPdfFile(item.images[0]) ? '/img/icon-pdf.png' : config.mediaUrl+item.images[0]} />
									</div>
								}
								<div className="caption">{item.title}</div>
							</div>
						</Link>)
					}
				</div>

				{
					projectEntries && projectEntries.length == 0 && !loading &&
					<NoResults />
				}
				{
					resultCount > pageSize &&
					<PaginationButtons resultCount={resultCount}
						pageSize={pageSize}
						previousUrl={previousUrl}
						nextUrl={nextUrl} />
				}

			</div>

	)
}

export default BookView;
